// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Set content for font-families */
.ql-picker-label{
    width: fit-content;
    overflow: auto;
  }
  .ql-font span[data-value="arial"]::before {
    font-family: Arial, sans-serif;
    content: "Arial" !important;
  }
  
  .ql-font span[data-value="times-new-roman"]::before {
    font-family: "Times New Roman";
    content: "Times New Roman" !important;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/texteditor/quill.css"],"names":[],"mappings":"AAAA,kCAAkC;AAClC;IACI,kBAAkB;IAClB,cAAc;EAChB;EACA;IACE,8BAA8B;IAC9B,2BAA2B;EAC7B;;EAEA;IACE,8BAA8B;IAC9B,qCAAqC;EACvC","sourcesContent":["/* Set content for font-families */\n.ql-picker-label{\n    width: fit-content;\n    overflow: auto;\n  }\n  .ql-font span[data-value=\"arial\"]::before {\n    font-family: Arial, sans-serif;\n    content: \"Arial\" !important;\n  }\n  \n  .ql-font span[data-value=\"times-new-roman\"]::before {\n    font-family: \"Times New Roman\";\n    content: \"Times New Roman\" !important;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
