import { memo, useState } from 'react';
import ReactQuill from 'react-quill';
import { Form } from 'antd';

import './quill.css';
import 'react-quill/dist/quill.snow.css';
import { modules, formats } from './quillConfig';

function TextEditor() {

  const [value, setValue] = useState('');
  // console.log('value' , value)
  return (
    <Form.Item
      label="Nội dung :"
      name="content_vi"
      rules={[{ required: true, message: 'Vui lòng nhập nội dung ' }]}
      className='mt-5 mb-14'
    >
      <ReactQuill
        className="h-[600px]"
        theme="snow"
        value={value}
        // onChange={setValue}
        modules={modules}
        formats={formats}
      />
      {/* <Input></Input> */}
    </Form.Item>
  );
}

export default memo(TextEditor);
